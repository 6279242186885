import React from 'react'

const ContactForm = () => {
    return (
        <form id="contact-form" method="post" action="https://getform.io/f/12437300-4987-427e-88c4-6f2054908a97">
            <label for="name" >
                <input placeholder="Full Name *" type="text" name="name" id="name" />
            </label>
            <label for="email">
                <input placeholder="Email Address" type="email" name="email" id="email" />
            </label>
            <label for="phone">
                <input placeholder="Phone" type="text" name="phone" id="phone" />
            </label>
            <label for="subject">
                <input placeholder="Subject" type="text" name="subject" id="subject" />
            </label>
            <label for="message">
                <textarea placeholder="How can we help you?" name="message" id="message" rows="5" />
            </label>
            <button type="submit">Send</button>
        </form>
    )
}

export default ContactForm