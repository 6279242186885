import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ContactForm from '../components/contact-form'
import Button from 'react-bootstrap/Button'


import phone from "../assets/images/phone.png"
import locationIcon from "../assets/images/location-icon.png"
import email from "../assets/images/email.png"

import { Breadcrumb } from 'gatsby-plugin-breadcrumb'



class ConnectPage extends React.Component {
  render() {

    const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
    const location = this.props.location
    const jobLink = get(this, 'props.data.allContentfulJobListingLink.edges[0].node.link')


    return (
      <Layout location={this.props.location}>
        <div className="inner-page-contact">
        <Container>
          <Breadcrumb location={location} crumbLabel="Connect" />
        </Container>
        <Row className="inner-page-banner connect-banner">
            <Col>
              <h2>CONNECT</h2>
              <h1>Get In Touch</h1>
              <a href={jobLink} target="_new"> <Button className="cta-button-banner">JOB LISTINGS</Button></a>
            </Col>
          </Row>
          </div>
          <Container>
              <Row className="space" style={{backgroundColor:'#FAFAFA'}}>
                  <Col md="12" style={{padding:'30px'}}>
                  <h2 className="green-headline">Contact Us</h2>  
                  <ContactForm/>
                  </Col>
              </Row>
              <Row className="space" id="contact-row">
                  <Col md="2">
                  <img class="contact-page-icon" src={email}/>
                  </Col>
                  <Col md="4">
                  <h3 class="small-black-headline">Email Address</h3>
                  <a href="mailto:info@v-techsolutions.net">info@v-techsolutions.net</a>
                  </Col>
                  <Col md="2">
                  <img class="contact-page-icon" src={phone}/>
                  </Col>
                  <Col md="4">
                  <h3 class="small-black-headline">Phone Contact</h3>
                  <a href="tel:2029868470">202.986.8470</a>
                  </Col>
              </Row>
          
          </Container>
      </Layout>
    )
  }
}

export default ConnectPage

export const pageQuery = graphql`
  query Connectquery {
    allContentfulTestimonial(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          title
          quote
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulJobListingLink {
      edges {
        node {
          link
        }
      }
    }
  }
`
